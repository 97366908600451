import { Input } from "../../../../components/common/Input";
import { Button } from "../../../../components/common/Button";
import { ReactComponent as ShareIcon } from "../../../../assets/icons/share.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning.svg";
import React, { useEffect } from "react";
import "./styles.css";
import { RootState } from "../../../../store";
import { HousingsActionCreator } from "../../../../store/housingsActions";
import { connect } from "react-redux";
import {
  selectIsPaid,
  selectIsTestPeriod,
  selectSharingDataId,
  selectSharingError,
  selectSharingPending,
  selectTestPeriodTill,
} from "../../../../store/selectors";
import { formatHousingObject, formatSharingData } from "./utils";
import { Error } from "../../../../components/common/Error";
import { Link } from "react-router-dom";
import { UserActionCreator } from "../../../../store/userActions";
import { TEST_SHARE_DATA } from "./constants";
import { InfoBar } from "../../../../components/common/InfoBar";

export const _ShareUSP = ({
  pending,
  error,
  sharingDataId,
  shareData,
  fetchSharingDataId,
  resetSharingDataId,
  housing,
                            isPaid,
  testPeriod,
  testPeriodTill,
  setNotification,
}) => {
  const path = window?.location.host;
  const showTestPeriodWarning = !isPaid && testPeriod;
  let url = `${path}/shared/housing/${sharingDataId}`;

  const finalHousingData = formatHousingObject(housing);
  const formattedShareData = formatSharingData(shareData);

  const finalShareData = showTestPeriodWarning
    ? TEST_SHARE_DATA
    : {
        ...formattedShareData,
        housingData: finalHousingData,
      };

  useEffect(() => {
    if (sharingDataId) {
      resetSharingDataId();
    }
  }, [shareData]);

  useEffect(() => {
    if (navigator.clipboard && sharingDataId) {
      navigator.clipboard.writeText(url).then(
        function () {
          console.log("Текст успешно скопирован в буфер обмена");
        },
        function (err) {
          console.error("Произошла ошибка при копировании текста: ", err);
        },
      );
    }
  }, [sharingDataId]);

  useEffect(() => {
    if (sharingDataId && testPeriod) {
      // setNotification({
      //   isOpen: true,
      //   value: {
      //     title: "Тестовый период",
      //     description:
      //         "Обратите внимание, что используется тестовая версия HusamHelper.\n" +
      //         "                  По ссылке будут отображены тестовые данные. Чтобы вопользоваться полной версией, оформите подписку",
      //     link: {
      //       title: "оформить подписку",
      //       href: "/payment",
      //     },
      //     closeIcon: true,
      //   },
      // });
    }
  }, [sharingDataId]);

  if (!shareData?.fullPrice) {
    return null;
  }

  const shareDataObject = {
    title: shareData.housingName,
    text: "Только посмотри, что для тебя подобрали!",
    url: `https://${url}`,
  };

  const handleShare = async () => {
    try {
      await navigator.share(shareDataObject);
    } catch (err) {
      console.log("error");
    }
  };

  if (sharingDataId) {
    return (
      <>
        {showTestPeriodWarning && (
          <InfoBar
            text="Обратите внимание, что используется тестовая версия HusamHelper. По ссылке будут отображены тестовые данные. Чтобы воспользоваться полной версией, оформите"
            link={{ href: "/payment", text: " подписку" }}
          />
        )}
        <div className="share-block_container">
          <Input value={url} />
          <Button
            icon={<ShareIcon height="18px" width="18px" />}
            iconOnly
            onClick={handleShare}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        {error && <Error title="Не удалось получить ссылку" />}
        <Button
          pending={pending}
          text={error ? "попробовать еще раз" : "получить ссылку"}
          onClick={() => fetchSharingDataId(finalShareData)}
          fullWidth
        />
      </>
    );
  }
};

const mapStateToProps = (state: RootState) => ({
  pending: selectSharingPending(state),
  error: selectSharingError(state),
  sharingDataId: selectSharingDataId(state),
  isPaid: selectIsPaid(state),
  testPeriod: selectIsTestPeriod(state),
  testPeriodTill: selectTestPeriodTill(state),
});

const mapDispatchToProps = {
  fetchSharingDataId: HousingsActionCreator.fetchSharingDataId,
  resetSharingDataId: HousingsActionCreator.sharingDataIdReset,
  setNotification: UserActionCreator.setNotification,
};

export const ShareUSP = connect(mapStateToProps, mapDispatchToProps)(_ShareUSP);
