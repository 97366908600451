export const handleUserResponse = (response) => {
    if (response.payload.status !== 200) {
        return ({
            pending: false,
            error: true,
            errorMessage: response.payload.data.message,
            userData: {
                id: null,
                email: null,
                name: null,
                token: null,
            }
        })
    }
    return ({
        pending: false,
        error: false,
        errorMessage: null,
        userData: response.payload.data,
    })
}

export const handleResponse = (response) => {
    if (response.payload.status !== 200) {
        return ({
            pending: false,
            error: true,
            errorMessage: response.payload.data.message,
            success: false,
        })
    }
    const confirmationUrl = response.payload.data.confirmation_url || '';
    return ({
        pending: false,
        error: false,
        errorMessage: null,
        success: true,
        confirmationUrl,
    })
}
