import React from "react";
import "./styles.css";
import { ReactComponent as Logo } from "../../../assets/logo_horizontal_color.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "Realty/store";
import { selectUserToken } from "../../../store/selectors";
import { isShared } from "../../../utils";
import { UserBar } from "./components/UserBar";

export const _Header = ({}) => {
  if (isShared) {
    return (
      <div className="header_container">
        <div className="header_logo">
          <Logo width="100%" height="100%" />
        </div>
      </div>
    );
  }

  return (
    <div className="header_container">
      <Link to="/">
        <div className="header_logo">
          <Logo width="100%" height="100%" />
        </div>
      </Link>
      <UserBar />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selectUserToken(state),
});

const mapDispatchToProps = {};

export const Header = connect(mapStateToProps, mapDispatchToProps)(_Header);
