import React, { useState } from "react";
import "./styles.css";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../../assets/icons/eye-slash.svg";

export const Input = ({
  error,
  errorMessage,
  register,
  value,
  onChange,
  name,
  type,
  placeholder,
  label,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const inputIcon = passwordVisible ? (
    <EyeSlashIcon width="100%" height="100%" stroke="#818c99" />
  ) : (
    <EyeIcon width="100%" height="100%" stroke="#818c99" />
  );
  const isPassword = type === "password";
  return (
    <>
      {label && <div className="input_label">{label}</div>}
      <div className="input_container">
        <input
          className={"input_main" + (error ? " input_error" : "")}
          name={name}
          type={passwordVisible ? 'text' : type}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...register}
        />
        {isPassword && (
          <div className="input_icon" onClick={() => setPasswordVisible(!passwordVisible)}>
            {inputIcon}
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="input_error-message">{errorMessage}</div>
      )}
    </>
  );
};
