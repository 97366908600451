import React, {useEffect} from "react";
import "./styles.css";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { Input } from "../../components/common/Input";
import { REGEXES } from "../../constants/common";
import { useForm } from "react-hook-form";
import { Button } from "../../components/common/Button";
import { UserActionCreator } from "../../store/userActions";
import {
  selectResetPassword,
  selectResetPasswordError,
  selectResetPasswordErrorMessage,
  selectResetPasswordPending,
  selectResetPasswordSuccess,
} from "../../store/selectors";
import { Link } from "react-router-dom";
import { ReactComponent as Chevron } from "../../assets/icons/chevron.svg";

const _ResetPassword = ({
  pending,
  error,
  errorMessage,
  success,
  resetPassword,
}) => {
  const {
    register,
    handleSubmit,
      setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const emailError = errors.email?.message;
  const onSubmit = (data) => {
    resetPassword(data);
  };

  useEffect(() => {
    setError("email", { message: errorMessage });
  }, [errorMessage, error]);

  if (success) {
    return (
      <div className="restore-password_container">
        <h2>Пароль обновлен</h2>
        <div className="restore-password_description">
          мы сгенерировали и отправили новый пароль на ваш e-mail
        </div>
        <Link to="/">продолжить</Link>
      </div>
    );
  }

  return (
      <>
        <Link className="restore-password_back-link" to={'/'}>
          <div className="chevron">
            <Chevron width="16" height="16" fill="#000"/>
          </div>
          назад
        </Link>
        <div className="restore-password_container">
          <h2>Сброс пароля</h2>
          <div className="restore-password_description">
            Введите адрес электронной почты, привязанный к вашей учетной записи.
            Мы сгенерируем и отправим на него новый пароль.
          </div>
          <form
              onSubmit={handleSubmit(onSubmit)}
              className="restore-password_form-container"
          >
            <div className="restore-password_input-item">
              <Input
                  register={register("email", {
                    required: {message: "введите e-mail", value: true},
                    pattern: {
                      message: "некорректный e-mail",
                      value: REGEXES.email,
                    },
                  })}
                  name="areaWorth"
                  type="email"
                  placeholder="e-mail"
                  label="введите e-mail"
                  error={!!emailError}
                  errorMessage={emailError}
              />
            </div>
            <div className="sign-in_input-item">
              <Button pending={pending} text="отправить"/>
            </div>
          </form>
        </div>
      </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pending: selectResetPasswordPending(state),
  error: selectResetPasswordError(state),
  errorMessage: selectResetPasswordErrorMessage(state),
  success: selectResetPasswordSuccess(state),
});

const mapDispatchToProps = {
  resetPassword: UserActionCreator.resetPassword,
};

export const ResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ResetPassword);
