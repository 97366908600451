import { createReducer } from "@reduxjs/toolkit";
import { UserActionCreator } from "./userActions.ts";
import { handleResponse, handleUserResponse } from "./utils";

const initialState = {
  pending: false,
  error: false,
  errorMessage: null,
  rememberMe: false,
  userData: {
    user: null,
    token: null,
  },
  verifyEmail: {
    pending: false,
    error: false,
    success: false,
  },
  resetPassword: {
    pending: false,
    error: false,
    errorMessage: null,
    success: false,
  },
  notification: {
    isOpen: false,
    value: null,
  },
  payment: {
    pending: false,
    error: false,
    errorMessage: null,
    success: false,
    confirmationUrl: null,
  }
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(UserActionCreator.userSignIn.pending, (state) => {
      state.pending = true;
      state.error = false;
      state.errorMessage = null;
    })
    .addCase(UserActionCreator.userSignIn.fulfilled, (state, action) => {
      state.pending = false;
      state.error = false;
      state.userData = action.payload;
    })
    .addCase(UserActionCreator.userSignIn.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(UserActionCreator.userSignUp.pending, (state) => {
      state.pending = true;
      state.error = false;
      state.errorMessage = null;
    })
    .addCase(UserActionCreator.userSignUp.fulfilled, (state, action) => {
      const result = handleUserResponse(action);
      state.pending = result.pending;
      state.error = result.error;
      state.errorMessage = result.errorMessage;
      state.userData = result.userData;
    })
    .addCase(UserActionCreator.getUserInfo.pending, (state) => {
      state.pending = true;
      state.error = false;
      state.errorMessage = null;
    })
    .addCase(UserActionCreator.getUserInfo.fulfilled, (state, action) => {
      state.pending = false;
      state.error = false;
      state.userData = action.payload;
    })
    .addCase(UserActionCreator.getUserInfo.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(UserActionCreator.logout, (state) => {
      state.userData.token = null;
      state.userData.user = null;
    })
    .addCase(UserActionCreator.setRememberMe, (state, action) => {
      state.rememberMe = action.payload;
    })
    .addCase(UserActionCreator.verifyEmail.pending, (state) => {
      state.verifyEmail.pending = true;
      state.verifyEmail.error = false;
    })
    .addCase(UserActionCreator.verifyEmail.fulfilled, (state) => {
      state.verifyEmail.pending = false;
      state.verifyEmail.error = false;
      state.verifyEmail.success = true;
    })
    .addCase(UserActionCreator.verifyEmail.rejected, (state) => {
      state.verifyEmail.pending = false;
      state.verifyEmail.error = true;
    })
    .addCase(UserActionCreator.setNotification, (state, action) => {
      state.notification.isOpen = action.payload.isOpen;
      state.notification.value = action.payload.value;
    })
    .addCase(UserActionCreator.resetPassword.pending, (state) => {
      state.resetPassword.pending = true;
      state.resetPassword.error = false;
      state.resetPassword.errorMessage = null;
      state.resetPassword.success = false;
    })
    .addCase(UserActionCreator.resetPassword.fulfilled, (state, action) => {
      const result = handleResponse(action);
      state.resetPassword = result;
    })
    .addCase(UserActionCreator.resetPassword.rejected, (state) => {
      state.resetPassword.pending = false;
      state.resetPassword.error = true;
    })
      .addCase(UserActionCreator.payment.pending, (state) => {
        state.payment.pending = true;
        state.payment.error = false;
        state.payment.errorMessage = null;
        state.payment.success = false;
      })
      .addCase(UserActionCreator.payment.fulfilled, (state, action) => {
        const result = handleResponse(action);
        state.payment = result;
      })
      .addCase(UserActionCreator.payment.rejected, (state) => {
        state.payment.pending = false;
        state.payment.error = true;
      });
});
