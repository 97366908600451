import React from "react";
import './styles.css';
import { ReactComponent as Chevron } from "../../../../../assets/icons/chevron.svg";

export const Credits = ({}) => {
    return (
        <details>
            <summary className="credits_summary">
                <div className="credits_title">Контакты и информация</div>
                <div className="credits_chevron">
                    <Chevron width="14" height="14" fill="#818c99"/>
                </div>
            </summary>
            <div className="credits_item">
                Батукаев Бибулат Асламбекович
            </div>
            <div className="credits_item">
                ИНН 201499765209
            </div>
            <div className="credits_item">
                Почтовый адрес: 366007, ул. Батукаева, д. 30
            </div>
            <div className="credits_item">
                Контактный телефон: +7 965 963 33 00
            </div>
        </details>
    );
}
