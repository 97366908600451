import React, { useEffect } from "react";
import "swiper/css/bundle";
import "./styles.css";
import { RootState } from "../../../../store/index.ts";
import { HousingsActionCreator } from "../../../../store/housingsActions.ts";
import { connect } from "react-redux";
import {
  selectSharedData,
  selectSharedError,
  selectSharedHousingData,
  selectSharedPending,
} from "../../../../store/selectors.ts";
import { FullInfo } from "./components/FullInfo";
import { useParams } from "react-router-dom";
import { HousingInfo } from "../../../HousingComplex/components/HousingInfo";
import { Error } from "../../../../components/common/Error";

export const _HousingComplexShared = ({
  error,
  pending,
  data,
  housing,
  fetchSharedData,
}) => {
  const sharedDataId = useParams()?.id;

  useEffect(() => {
    fetchSharedData(sharedDataId);
  }, []);

  if (pending) {
    return null;
  }

  if (error) {
    return <Error retryCallback={() => fetchSharedData(sharedDataId)} />;
  }

  return (
    <>
      <HousingInfo housing={housing} />
      <FullInfo data={data} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pending: selectSharedPending(state),
  error: selectSharedError(state),
  data: selectSharedData(state),
  housing: selectSharedHousingData(state),
});

const mapDispatchToProps = {
  fetchSharedData: HousingsActionCreator.fetchSharedData,
};

export const HousingComplexShared = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_HousingComplexShared);
