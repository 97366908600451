import React from "react";
import "./App.css";
import { realtyStore } from "./Realty/store";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";
import { SignUp } from "./Realty/pages/SignUp";
import { SignIn } from "./Realty/pages/SignIn";
import { CookiesProvider } from "react-cookie";
import { AuthHoc } from "./Realty/components/hocs/AuthHoc";
import { HousingComplexShared } from "./Realty/pages/Shared/components/HousingComplexShared";
import { Header } from "./Realty/components/common/Header";
import { ImagesUpload } from "./Realty/pages/ImagesUpload";
import { Footer } from "./Realty/components/common/Footer";
import { VerifyEmail } from "./Realty/pages/VerifyEmail";
import { Payment } from "./Realty/pages/Payment";
import { Notification } from "./Realty/components/common/Notification";
import { ResetPassword } from "./Realty/pages/ResetPassword";
import { isTestStand } from "./Realty/constants/common";
import { Housings } from "./Realty/pages/Housings";
import { HousingComplex } from "./Realty/pages/HousingComplex";

function App() {
  if (isTestStand) {
    console.log("Current Git Branch:", process.env.REACT_APP_GIT_BRANCH);
  }
  return (
    <>
      <CookiesProvider>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Provider store={realtyStore}>
          <BrowserRouter>
            <AuthHoc>
              <Header />
              <Routes>
                <Route path="/" element={<Housings />} />
                <Route
                  path="/housing-complex/:id/"
                  element={<HousingComplex />}
                />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/payment" element={<Payment />} />
                <Route path="/images-upload" element={<ImagesUpload />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                  path="/shared/housing/:id/"
                  element={<HousingComplexShared />}
                />
                <Route path="/profile/verify/" element={<VerifyEmail />} />
              </Routes>
              <Footer />
              <Notification />
            </AuthHoc>
          </BrowserRouter>
        </Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
