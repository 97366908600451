export const TEST_SHARE_DATA = {
    "floor": "1-20",
    "flatType": "Обычные",
    "area": "50",
    "installmentOption": "full",
    "areaWorth": "100000",
    "fullPrice": 5000000,
    "housingData": {
        "housingName": "Test Complex",
        "address": "Фултон, 285, боро Манхэттен, Нью-Йорк",
        "builder": "Worthington, Skilling, Helle & Jackson",
        "deadline": "11.09.2001",
        "images": [
            "https://husamhelper.ru/storage/images/phptdeptf48uo7u2L3gaET.jpg",
            "https://husamhelper.ru/storage/images/phpcnuqdc9p7pgof2igGfb.jpeg",
            "https://husamhelper.ru/storage/images/php5qcmpi7eepimcE1mhiR.webp"
        ],
        "layouts": [
            {
                "name": "Здание 1",
                "src": "https://drive.google.com/file/d/1HxUEPBP8A87nWIK1fq1vWnN9foyF65gT/view?usp=drive_link"
            },
            {
                "name": "Здание 2",
                "src": "https://drive.google.com/file/d/19vFqyvwZX4-0ug7K9olk3hdFIJSx6457/view?usp=drive_link"
            }
        ]
    }
}
