import React, { useState } from "react";
import "./styles.css";
import { PlanItem } from "./components/PlanItem";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";

export const Plans = ({ title, chessPlans, defaultName }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <details>
          <summary>
              <div className="plans_title">{title}</div>
              <Chevron className="plans_chevron" width="16" height="16"/>
          </summary>
      </details>
        <ul className="plans_list">
            {chessPlans.map((item) => (
          <PlanItem item={item} defaultName={defaultName} />
        ))}
      </ul>
    </>
  );
};
