import { Slider } from "../../components/Slider";
import React, { useState } from "react";
import { getClippedText } from "../../utils";
import './styles.css';

export const HousingInfo = ({ housing }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const CLIPPED_TEXT_LENGTH = 10;
  const { clippedText, textLength } = getClippedText(
    housing.description,
    CLIPPED_TEXT_LENGTH,
  );
  const canBeClipped = textLength && textLength > CLIPPED_TEXT_LENGTH;
  const description = isDescriptionExpanded ? housing.description : clippedText;
  return (
    <>
      <Slider images={housing?.images} />
      <div className="housing_info_container">
        <div className="housing_info_title">{housing.housingName}</div>
        <div className="housing_info_info">
          <div>{housing.builder || "нет информации о застройщике"}</div>
          <div>{housing.address || "нет информации о застройщике"}</div>
          <div>{housing.deadline || "нет информации о дате сдачи"}</div>
        </div>
        <div
            className="housing_info_description"
            onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
        >
          {description || "нет описания"}
          {!isDescriptionExpanded && canBeClipped && <b> подробнее...</b>}
        </div>
      </div>
    </>
  );
};
