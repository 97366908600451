import React from "react";
import "./styles.css";
import { Credits } from "./components/Credits";
import { Link } from "react-router-dom";
import { isShared } from "../../../utils";

export const Footer = () => {
  if (isShared) {
    return null;
  }
  return (
    <div className="footer_container">
      <div>© 2024 HusamHelper</div>
      <Credits />
      <Link
        target="_blank"
        to={
          "https://docs.google.com/document/d/1FE3ScyafSTWEE1c7StJug0uWI8aAPBH0iIS5fBzhFDk/edit?usp=sharing"
        }
      >
        Политика обработки персональных данных
      </Link>
      <Link
        target="_blank"
        to={
          "https://docs.google.com/document/d/1jnXlEkBSCxy9N33YrKEY3KLDxwrgOLXOyYoc4Bw6XzU/edit?usp=sharing"
        }
      >
        Публичная оферта
      </Link>
    </div>
  );
};
