import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../utils/index.ts";
import { API_ENDPOINTS } from "../constants/common.ts";

const HousingsActionTypes = {
  FETCH_FLATS_TABLE: "flatsTable/fetch",
  FETCH_PRICES_BY_FIRST_PAYMENT: "pricesByFirstPayment/fetch",
  FETCH_PRICES_BY_PERIOD: "pricesByPeriod/fetch",
  FETCH_HOUSINGS: "housings/fetch",
  FETCH_INSTALLMENT_PLAN_TYPES: "installmentPlanTypes/fetch",
  FETCH_SHARED_DATA: "sharedData/fetch",
  FETCH_SHARING_DATA_ID: "sharingDataId/fetch",
  SHARING_DATA_ID_RESET: "sharingDataId/reset",
};

export const HousingsActionCreator = {
  fetchFlatsTable: createAsyncThunk(
    HousingsActionTypes.FETCH_FLATS_TABLE,
    async (id: number) => {
      const result = await apiClient.post(API_ENDPOINTS.flatsTable);

      return result.data;
    },
  ),
  fetchHousings: createAsyncThunk(
    HousingsActionTypes.FETCH_HOUSINGS,
    async (id: number) => {
      const result = await apiClient.post(API_ENDPOINTS.housings);

      return result.data;
    },
  ),
  fetchPricesByFirstPayment: createAsyncThunk(
    HousingsActionTypes.FETCH_PRICES_BY_FIRST_PAYMENT,
    async (housingName: string) => {
      const result = await apiClient.post(
        API_ENDPOINTS.housingPricesByFirstPayment,
        {
          housingName,
        },
      );

      return result.data;
    },
  ),
  fetchPricesByPeriod: createAsyncThunk(
    HousingsActionTypes.FETCH_PRICES_BY_PERIOD,
    async (housingName: string) => {
      const result = await apiClient.post(API_ENDPOINTS.housingPricesByPeriod, {
        housingName,
      });

      return result.data;
    },
  ),
  fetchInstallmentPlanTypes: createAsyncThunk(
    HousingsActionTypes.FETCH_INSTALLMENT_PLAN_TYPES,
    async (housingName: string) => {
      const result = await apiClient.post(API_ENDPOINTS.installmentPlanTypes, {
        housingName,
      });

      return result.data;
    },
  ),
  fetchSharedData: createAsyncThunk(
    HousingsActionTypes.FETCH_SHARED_DATA,
    async (id: string) => {
      const result = await apiClient.get(`${API_ENDPOINTS.getSharedData}${id}/`);

      return result.data;
    },
  ),
  fetchSharingDataId: createAsyncThunk(
    HousingsActionTypes.FETCH_SHARING_DATA_ID,
    async (data) => {
      const result = await apiClient.post(API_ENDPOINTS.getSharingDataId, {
        ...data,
      });

      return result.data;
    },
  ),
  sharingDataIdReset: createAction(HousingsActionTypes.SHARING_DATA_ID_RESET),
};
