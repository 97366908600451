import React, { useEffect, useState } from "react";
import "./styles.css";
import { HousingsActionCreator } from "../../store/housingsActions.ts";
import { RootState } from "../../store/index.ts";
import {
  selectHousingsError,
  selectHousingsList,
  selectHousingsPending,
} from "../../store/selectors.ts";
import { connect } from "react-redux";
import { HousingItem } from "./HousingItem";
import { HousingsSkeleton } from "./HousingsSkeleton";
import { Search } from "../../components/Search";
import { Error } from "../../components/common/Error";
import { useNavigate } from "react-router-dom";

export const _Housings = ({
  pending,
  error,
  housings,
  fetchHousings,
  setSelectedHousing,
}) => {
  const [searchResults, setSearchResults] = useState();
  const housingArray = searchResults || housings;
  const navigate = useNavigate();
  const handleOpenHousing = (id: number) => {
    navigate(`/housing-complex/${id}/`);
  };
  useEffect(() => {
    if (!housings) {
      fetchHousings();
    }
  }, []);

  useEffect(() => {
    if (housings) {
      window.scrollTo(0, 0);
    }
  }, [housings]);

  if (pending) {
    return <HousingsSkeleton />;
  }

  if (error) {
    return <Error retryCallback={fetchHousings} />;
  }

  return (
    <>
      <div className="housings_container">
        <div className="housings_search-bar">
          <Search onResult={setSearchResults} housings={housings} />
        </div>
        <ul className="housings_list">
          {housingArray.map((housing) => (
            <HousingItem
              key={housing.id}
              housing={housing}
              onClick={() => handleOpenHousing(housing.id)}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  fetchFlatsTable: HousingsActionCreator.fetchFlatsTable,
  fetchHousings: HousingsActionCreator.fetchHousings,
};

const mapStateToProps = (state: RootState) => ({
  pending: selectHousingsPending(state),
  error: selectHousingsError(state),
  housings: selectHousingsList(state),
});

export const Housings = connect(mapStateToProps, mapDispatchToProps)(_Housings);
