export const isFloat = (value) => {
  return typeof value === 'number' && !Number.isNaN(value) && value % 1 !== 0;
};

export const formatHousingObject = (housing) => {
  let formattedHousing = {};
  Object.keys(housing).map((item) => {
    if (housing[item]?.length > 0) {
      formattedHousing[item] = housing[item];
    }
  });
  if (formattedHousing.chessPlans && formattedHousing.chessPlans.length > 0) {
    const chessPlans = formattedHousing.chessPlans.map(item => ({
      name: item.name || 'Шахматка',
      src: item.src || '',
    }));
    formattedHousing.chessPlans = chessPlans;
  }
  if (formattedHousing.layouts && formattedHousing.layouts.length > 0) {
    const layouts = formattedHousing.layouts.map(item => ({
      name: item.name || 'Общий план',
      src: item.src || '',
    }));
    formattedHousing.layouts = layouts;
  }
  return formattedHousing;
};

export const formatSharingData = (data) => {
  let formattedSharingData = {};
  if (data) {
    if (data?.installmentPeriod < 2) {
      data.installmentPeriod = undefined;
    }
    Object.entries(data).forEach(([key, value]) => {
      if (value?.length > 0 || value > 0) {
        formattedSharingData[key] = isFloat(value) ? Math.ceil(value) : value;
      }
    });
  }
  return formattedSharingData;
}
