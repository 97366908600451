import React from "react";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/error.svg";
import "./styles.css";
import { DEFAULT_ERROR_TEXTS } from "./constants";
import { Button } from "..//Button";

export const Error = ({
  title = DEFAULT_ERROR_TEXTS.title,
  description = DEFAULT_ERROR_TEXTS.description,
  retryCallback,
    buttonText = "попробовать еще раз",
    icon = <ErrorIcon />,
}) => {
  return (
    <div className="error_container">
      <div className="error_icon">
          {icon}
      </div>
      <div className="error_main">
        <div className="error_title">{title}</div>
        <div className="error_description">{description}</div>
          {retryCallback && (
              <div className="error_retry_button">
                  <Button onClick={retryCallback} text={buttonText} />
              </div>
          )}
      </div>
    </div>
  );
};
