import React from "react";
import "./styles.css";
import { DefaultAvatar } from "../DefaultAvatar";

export const AccountItem = ({ account }) => {
  return (
    <div className="account-item_container">
      <DefaultAvatar
        name={account.user.name}
        color={account.user.avatarColor}
      />
      <div className="account-item_info">
        <div className="account-item_title">{account.user.name}</div>
        <div className="account-item_description">риэлтор</div>
      </div>
    </div>
  );
};
