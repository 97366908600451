import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import "swiper/css/bundle";
import "./styles.css";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImageBiggerSide } from "../../../../utils";
import { IMAGE_SIDES } from "../../../../constants/common";

export const Slider = ({ images }) => {
  const [slide, setSlide] = useState(0);
  const [biggerSide, setBiggerSide] = useState("width");
  const ref = useRef(null);
  const imgRef = useRef(null);
  const hasImages = images && images?.length > 0;
  const imageUrl = hasImages ? images[slide] : "";
  const handleChangeSlide = (index) => {
    setSlide(index);
    ref.current.style.setProperty(
      "background",
      `url("${imageUrl}") no-repeat cover`,
    );
  };

  useEffect(() => {
    setBiggerSide(getImageBiggerSide(imageUrl));
  }, [imageUrl]);

  if (!hasImages) {
    return <>нет фотографий</>;
  }
  return (
    <>
      <div
        className="slider_container"
        style={{
          backgroundImage: `url(${imageUrl}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        ref={ref}
      >
        <div className="slider_blur">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{
              clickable: true,
              el: ".slider_pagination",
            }}
            scrollbar={{ draggable: true }}
            style={{
              "--swiper-pagination-color": "#FFC800",
              "--swiper-navigation-color": "#FFC800",
            }}
            onSlideChange={(swiper) => handleChangeSlide(swiper.activeIndex)}
          >
            {images.map((image, index) => (
              <SwiperSlide>
                <img
                  src={image}
                  alt={`image ${index}`}
                  ref={imgRef}
                  width={biggerSide === IMAGE_SIDES.width ? "100%" : "auto"}
                  height={biggerSide === IMAGE_SIDES.height ? "100%" : "auto"}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="slider_pagination" />
      </div>
    </>
  );
};
