import React, { useState } from "react";
import "./styles.css";
import { RootState } from "../../../../../store";
import {
  selectUserData,
  selectUserToken,
} from "../../../../../store/selectors";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";
import { SideMenu } from "./components/SideMenu";
import { DefaultAvatar } from "../../../../../components/common/DefaultAvatar";

const _UserBar = ({ token, userData }) => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const userToken = cookies.userToken;
  const isAuth = !!userToken || !!token;

  if (!isAuth) {
    return null;
  }

  return (
    <>
      <button
        className="userbar_logout-button"
        onClick={() => setSideMenuIsOpen(true)}
      >
        <DefaultAvatar
          name={userData.user.name}
          color={userData.user.avatarColor}
          width={36}
          height={36}
        />
      </button>
      <SideMenu isOpen={sideMenuIsOpen} setIsOpen={setSideMenuIsOpen} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selectUserToken(state),
  userData: selectUserData(state),
});

export const UserBar = connect(mapStateToProps, null)(_UserBar);
