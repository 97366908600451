import React from "react";
import "./styles.css";
import { ReactComponent as Loader } from "../../../assets/loading_icon.svg";
import {getButtonContent} from "./utils";

export const Button = ({ icon, iconOnly, text, iconLeft, iconRight, onClick, pending, fullWidth }) => {
  const content = getButtonContent(icon, text, iconOnly);
  return (
    <button onClick={onClick} className={"button_main " + (fullWidth ? " button_full-width" : "")}>
      {pending ? <Loader height="18px" width="18px" fill="#fff" stroke="#fff" /> : content}
    </button>
  );
};
