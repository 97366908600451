import React from "react";
import './styles.css';

export const Checkbox = ({checked, onChange, className, label}) => {
    return (
        <div className={"checkbox_container " + (className ? className : "")} onClick={() => onChange(!checked)}>
            <div className={"checkbox_toggle" + (checked ? " checkbox_toggle-checked" : "")}>
                <div className="checkbox_circle" />
            </div>
            <div className="checkbox_label">
                {label}
            </div>
        </div>
    );
};
