import {ReactComponentElement} from "react";

export const getButtonContent = (icon: ReactComponentElement<any>, text, iconOnly) => {

    if (icon && iconOnly) {
        return icon;
    }

    if (icon && text && !iconOnly) {
        return icon + ' ' + text;
    }

    return text;
}
