import React, { useEffect, useState } from "react";
import "./styles.css";
import { useDropzone } from "react-dropzone";
import { RootState } from "../../store";
import { AdminActionCreator } from "../../store/adminActions";
import { connect } from "react-redux";
import {
  selectUploadFilesError,
  selectUploadFilesErrorMessage,
  selectUploadFilesPaths,
  selectUploadFilesPending,
} from "../../store/selectors";
import { Button } from "../../components/common/Button";
import { bytesToMegabytes } from "./utils";

export const _ImagesUpload = ({
  pending,
  error,
  errorMessage,
  paths,
  upload,
}) => {
  const [progress, setProgress] = useState();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [urlArray, setUrlArray] = useState([]);
  const urlsString = [...urlArray];

  const handleSend = () => {
    upload({ files: acceptedFiles, setProgress });
    setUrlArray([]);
  };

  useEffect(() => {
    if (paths?.length > 0 && paths?.length > urlArray.length) {
      setUrlArray(paths.map((item) =>
        (`https://husamhelper.ru/storage/${item}`),
      ));
    }
  }, [paths]);

  const files = acceptedFiles.map((file) => {
    const convertedSize = bytesToMegabytes(file.size, 2);
    return (
      <li className="upload-files_uploading-file" key={file.path}>
        {file.path} - {convertedSize} bytes
      </li>
    );
  });

  return (
    <>
      <div className="upload-files_container">
        <div {...getRootProps({ className: "upload-files_dropzone" })}>
          <input {...getInputProps()} />
          <div className="upload-files_dropzone-text">
            перетащите файлы сюда или кликните
          </div>
        </div>
        <div>
          <h4>файлы:</h4>
          <ul className="upload-files_uploading-files">{files}</ul>
        </div>
        <Button onClick={handleSend} pending={pending} text="загрузить" />
        {paths && (
          <>
            <textarea
              className="upload-files_url-array"
              value={urlsString}
            />
            <ul className="upload-files_uploaded-images">
              {paths.map((item, index) => (
                <>
                  <li
                    className="upload-files_uploaded_image"
                    style={{
                      background: `url(https://husamhelper.ru/storage/${item})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />
                </>
              ))}
            </ul>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pending: selectUploadFilesPending(state),
  error: selectUploadFilesError(state),
  errorMessage: selectUploadFilesErrorMessage(state),
  paths: selectUploadFilesPaths(state),
});

const mapDispatchToProps = {
  upload: AdminActionCreator.uploadFiles,
};

export const ImagesUpload = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_ImagesUpload);
