import React, { useEffect } from "react";
import "./styles.css";
import { RootState } from "../../../store";
import { UserActionCreator } from "../../../store/userActions";
import { connect } from "react-redux";
import {
  selectNotificationIsOpen,
  selectNotificationValue,
} from "../../../store/selectors";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { Link } from "react-router-dom";
import { Button } from "../Button";
import {useCookies} from "react-cookie";

const _Notification = ({ isOpen, notification, setNotification }) => {
  const [cookies, setCookie] = useCookies(['cookieUsingAcceptance']);
  const cookieAccepted = cookies.cookieUsingAcceptance;
  const handleClose = () => {
    setNotification({ isOpen: false });
    setTimeout(() => setNotification({ value: null }), 1000);
  };

  useEffect(() => {
    if (!cookieAccepted) {
      setNotification({
        isOpen: true,
        value: {
          title: "Cookies",
          description:
              "Этот сайт использует файлы cookie. Продолжая использовать этот сайт, вы соглашаетесь на их использование. Подробнее по ссылке ниже",
          link: {
            title: "политика обработки персональных данных",
            href: "https://docs.google.com/document/d/1FE3ScyafSTWEE1c7StJug0uWI8aAPBH0iIS5fBzhFDk/edit?usp=sharing",
          },
          button: {
            title: "согласен",
            onClick: () => setNotification({ isOpen: false }),
          },
        },
      });
      setCookie("cookieUsingAcceptance", true, { path: "/" });
    }
    if (notification && notification?.title === 'Cookie' && cookieAccepted) {
        setNotification({ isOpen: false, value: null })
    }
  }, []);

  return (
    <div
      className="notification-container"
      style={{
        bottom: isOpen ? 0 : "-600px",
      }}
    >
      {notification?.closeIcon && (
          <div className="notification-close" onClick={handleClose}>
            <CloseIcon stroke="#eee" width="100%" height="100%"/>
          </div>
      )}
      <div className="notification-title">{notification?.title}</div>
      <div className="notification-description">
        {notification?.description}
      </div>
      {notification?.link && (
          <Link target="_blank" className="notification-link" to={notification.link.href}>{notification.link.title}</Link>
      )}
      {notification?.button && (
        <Button
          onClick={notification.button.onClick}
          text={notification.button.title}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: selectNotificationIsOpen(state),
  notification: selectNotificationValue(state),
});

const mapDispatchToProps = {
  setNotification: UserActionCreator.setNotification,
};

export const Notification = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_Notification);
