import { createReducer } from "@reduxjs/toolkit";
import { HousingsActionCreator } from "./housingsActions.ts";

const initialState = {
  flats: {
    pending: true,
    error: false,
    data: null,
  },
  housings: {
    pending: true,
    error: false,
    data: null,
  },
  housingPricesByFirstPayment: {
    pending: true,
    error: false,
    data: null,
  },
  housingPricesByPeriod: {
    pending: true,
    error: false,
    data: null,
  },
  installmentPlanTypes: {
    pending: true,
    error: false,
    data: null,
  },
  sharedData: {
    pending: true,
    error: false,
    data: null,
  },
  sharingData: {
    pending: false,
    error: false,
    id: null,
  },
};

export const housingsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(HousingsActionCreator.fetchFlatsTable.pending, (state) => {
      state.flats.pending = true;
    })
    .addCase(
      HousingsActionCreator.fetchFlatsTable.fulfilled,
      (state, action) => {
        state.flats.pending = false;
        state.flats.error = false;
        state.flats.data = action.payload;
      },
    )
    .addCase(HousingsActionCreator.fetchFlatsTable.rejected, (state) => {
      state.flats.pending = false;
      state.flats.error = true;
    })
    .addCase(HousingsActionCreator.fetchHousings.pending, (state) => {
      state.housings.pending = true;
    })
    .addCase(HousingsActionCreator.fetchHousings.fulfilled, (state, action) => {
      state.housings.pending = false;
      state.housings.error = false;
      state.housings.data = action.payload;
    })
    .addCase(HousingsActionCreator.fetchHousings.rejected, (state) => {
      state.housings.pending = false;
      state.housings.error = true;
    })
    .addCase(
      HousingsActionCreator.fetchInstallmentPlanTypes.pending,
      (state) => {
        state.installmentPlanTypes.pending = true;
      },
    )
    .addCase(
      HousingsActionCreator.fetchInstallmentPlanTypes.fulfilled,
      (state, action) => {
        state.installmentPlanTypes.pending = false;
        state.installmentPlanTypes.error = false;
        state.installmentPlanTypes.data = action.payload;
      },
    )
    .addCase(
      HousingsActionCreator.fetchInstallmentPlanTypes.rejected,
      (state) => {
        state.installmentPlanTypes.pending = false;
        state.installmentPlanTypes.error = true;
      },
    )
    .addCase(
      HousingsActionCreator.fetchPricesByFirstPayment.pending,
      (state) => {
        state.housingPricesByFirstPayment.pending = true;
      },
    )
    .addCase(
      HousingsActionCreator.fetchPricesByFirstPayment.fulfilled,
      (state, action) => {
        state.housingPricesByFirstPayment.pending = false;
        state.housingPricesByFirstPayment.error = false;
        state.housingPricesByFirstPayment.data = action.payload;
      },
    )
    .addCase(
      HousingsActionCreator.fetchPricesByFirstPayment.rejected,
      (state) => {
        state.housingPricesByFirstPayment.pending = false;
        state.housingPricesByFirstPayment.error = true;
      },
    )
    .addCase(HousingsActionCreator.fetchPricesByPeriod.pending, (state) => {
      state.housingPricesByPeriod.pending = true;
    })
    .addCase(
      HousingsActionCreator.fetchPricesByPeriod.fulfilled,
      (state, action) => {
        state.housingPricesByPeriod.pending = false;
        state.housingPricesByPeriod.error = false;
        state.housingPricesByPeriod.data = action.payload;
      },
    )
    .addCase(HousingsActionCreator.fetchPricesByPeriod.rejected, (state) => {
      state.housingPricesByPeriod.pending = false;
      state.housingPricesByPeriod.error = true;
    })
    .addCase(HousingsActionCreator.fetchSharedData.pending, (state) => {
      state.sharedData.pending = true;
    })
    .addCase(
      HousingsActionCreator.fetchSharedData.fulfilled,
      (state, action) => {
        state.sharedData.pending = false;
        state.sharedData.error = false;
        state.sharedData.data = action.payload;
      },
    )
    .addCase(HousingsActionCreator.fetchSharedData.rejected, (state) => {
      state.sharedData.pending = false;
      state.sharedData.error = true;
    })
    .addCase(HousingsActionCreator.fetchSharingDataId.pending, (state) => {
      state.sharingData.pending = true;
    })
    .addCase(
      HousingsActionCreator.fetchSharingDataId.fulfilled,
      (state, action) => {
        state.sharingData.pending = false;
        state.sharingData.error = false;
        state.sharingData.id = action.payload.id;
      },
    )
    .addCase(HousingsActionCreator.fetchSharingDataId.rejected, (state) => {
      state.sharingData.pending = false;
      state.sharingData.error = true;
    })
    .addCase(HousingsActionCreator.sharingDataIdReset, (state) => {
      state.sharingData.id = null;
    });
});
