import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { housingsReducer } from "./housingsReducer.ts";
import { userReducer } from "./userReducer.ts";
import { adminReducer } from "./adminReducer";

export const commonReducer = combineReducers({
  housingsReducer,
  userReducer,
  adminReducer,
});
