export const getClippedText = (text, number) => {
  const textArray = text && text.split(" ");
  let clippedTextArray = [];
  if (number && textArray?.length > number) {
    textArray.map((word: string) => word && clippedTextArray.push(word));
    const clippedText = clippedTextArray.slice(0, number).join(" ");
    return {
      clippedText,
      textLength: clippedTextArray.length,
    };
  }
  return {
    clippedText: text,
    textLength: number,
  };
};

export const getHousingById = (housings, id) => {
  let housing = {};
  if (housings && id) {
    Object.keys(housings).map(key => {
      if (housings[key].id === Number(id)) {
        housing = housings[key];
      }
    });
  }
  return housing;
}
