import React from "react";
import { ReactComponent as PlanIcon } from "../../../../../../assets/icons/document.svg";
import { ReactComponent as LinkIcon } from "../../../../../../assets/icons/link.svg";

export const PlanItem = ({ item, defaultName }) => {
  const handleClickPlan = (src: string) => {
    window.open(src, "_blank");
  };

  return (
    <li
      className="plans_item"
      onClick={() => handleClickPlan(item.src)}
    >
      <div className="plans_icon">
        <PlanIcon width="32" height="32" />
      </div>
      <div className="plans_item-title">{item.name || defaultName}</div>
      <div className="plans_link-icon">
        <LinkIcon width="16" height="16" />
      </div>
    </li>
  );
};
