import React from "react";
import "./styles.css";
import Select from "react-select";

export const SelectInstallmentOption = ({
  options,
  selectedOption,
  setSelectedOption,
  label,
}) => {
  const styles = {
    menu: (styles) => ({
      ...styles,
      background: "#fff",
      border: "1px solid #939baa",
    }),
    control: (styles) => ({
      ...styles,
      background: "#fff",
      border: "1px solid #939baa",
      borderRadius: "8px",
      height: "42px",
      color: "#818C99",
    }),
    option: (styles) => ({
      ...styles,
      background: "#fff",
      borderBottom: "1px solid #939baa",
      ":last-child": {
        border: "none",
      },
      ":hover": {
        ...styles["hover"],
        background: "#D4D5D5",
      },
      ":active": {
        ...styles["active"],
        text: "#818C99",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#818C99",
    }),
  };

  const formattedOptions = Object.values(options).map((item) => ({
    value: item,
    label: item.name,
  }));
  const handleSelectInstallmentOption = (value: string) => {
    const targetOption = Object.values(options).find(
      (item) => item.name === value.value.name,
    );
    if (targetOption) {
      setSelectedOption(targetOption);
    }
  };

  return (
    <>
      <div className="select-installment-option_label">
        {label}
      </div>
      <Select
        //className="select-installment-option_main"
        value={
          selectedOption && {
            label: selectedOption.name,
            value: selectedOption,
          }
        }
        onChange={(e) => handleSelectInstallmentOption(e)}
        options={formattedOptions}
        styles={styles}
        placeholder={label}
        menuPlacement="auto"
        className="select-installment-option_main"
      />
    </>
  );
};
