import React from "react";
import "./styles.css";
import { ReactComponent as CloseIcon } from "../../../../../../../assets/icons/close.svg";
import { ReactComponent as LogoutIcon } from "../../../../../../../assets/icons/logout.svg";
import { AccountItem } from "../../../../../../../components/common/AccountItem";
import { RootState } from "../../../../../../../store";
import { selectUserData } from "../../../../../../../store/selectors";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";
import { UserActionCreator } from "../../../../../../../store/userActions";
import { PaidPeriod } from "../PaidPeriod";
import { TestPeriod } from "../TestPeriod";

const _SideMenu = ({ userData, isOpen, setIsOpen, logout }) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const paidTillDate = userData.user.paidTill
    ? new Date(userData.user.paidTill).toLocaleString("ru-RU", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : undefined;
  const testPeriodTillDate = userData.user.testPeriodTill
    ? new Date(userData.user.testPeriodTill).toLocaleString("ru-RU", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : undefined;
  const shouldRenderPaid = !userData.user.testPeriod || userData.user.paid;
  const shouldRenderTest = userData.user.testPeriod && !userData.user.paid;
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    removeCookie("userToken", { path: "/" });
    removeCookie("rememberMe", { path: "/" });
    logout();
  };

  return (
    <>
      <div
        className="side-menu_wrapper"
        style={{ display: isOpen ? "block" : "none" }}
        onClick={handleClose}
      />
      <div
        className={"side-menu_container " + (isOpen ? "side-menu_opened" : "")}
      >
        <div className="side-menu_close" onClick={handleClose}>
          <CloseIcon stroke="#818c99" width="100%" height="100%" />
        </div>
        <AccountItem account={userData} />
        {shouldRenderPaid && (
            <PaidPeriod
                paid={userData.user.paid}
                paidTill={paidTillDate}
                handleClose={handleClose}
            />
        )}
        {shouldRenderTest && (
          <TestPeriod
            testPeriod={userData.user.testPeriod}
            testPeriodTill={testPeriodTillDate}
            handleClose={handleClose}
          />
        )}
        <button className="side-menu_logout" onClick={handleLogout}>
          <div className="side-menu_logout-icon">
            <LogoutIcon stroke="#f00000" width="100%" height="100%" />
          </div>
          Выйти
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  userData: selectUserData(state),
});

const mapDispatchToProps = {
  logout: UserActionCreator.logout,
};

export const SideMenu = connect(mapStateToProps, mapDispatchToProps)(_SideMenu);
