import React, { useEffect } from "react";
import "./styles.css";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as Chevron } from "../../assets/icons/chevron.svg";
import { Button } from "../../components/common/Button";
import { Link } from "react-router-dom";
import {
  selectPaymentConfirmationUrl,
  selectPaymentError,
  selectPaymentErrorMessage,
  selectPaymentPending,
  selectPaymentSuccess,
  selectUserData,
} from "../../store/selectors";
import { UserActionCreator } from "../../store/userActions";
import { InfoBar } from "../../components/common/InfoBar";

const _Payment = ({
  pending,
  error,
  errorMessage,
  success,
  confirmationUrl,
  userData,
  paymentRequest,
}) => {
  const pay = () => {
    paymentRequest({
      email: userData.user.email,
      amount: 2990,
      returnUrl: "https://husamhelper.ru",
      description: "Оплата тарифа \"личный\"",
    });
  };

  useEffect(() => {
    if (success && confirmationUrl) {
      window.location.href = confirmationUrl;
    }
  }, [confirmationUrl, success]);

  if (!userData) {
    return null;
  }

  return (
    <>
      <Link className="payment_back-link" to={"/"}>
        <div className="chevron">
          <Chevron width="16" height="16" fill="#000" />
        </div>
        назад
      </Link>
      <div className="payment_container">
        <h2>Тарификация</h2>
        {error && (
            <InfoBar
                className="payment_error"
                type="error"
                text="Ошибка оплаты. Попробуйте еще раз немного позже."
            />
        )}
        <div className="payment_tariff-list">
          <div className="payment_tariff-container">
            <div className="payment_tariff">
              <div className="payment_tariff-title">личный</div>
              <div className="payment_tariff-price">2990₽</div>
              <div className="payment_tariff-description">
                За 1 месяц
                <p>
                  Тариф создан специально для профессионалов рынка недвижимости,
                  стремящихся к максимальной эффективности и удобству в работе
                </p>
              </div>
              <ul className="payment_tariff-options">
                <li>
                  <div className="payment_check-icon">
                    <CheckIcon fill="#c06198" width="100%" height="100%" />
                  </div>
                  <div className="payment_tariff-option-text">
                    полный доступ к базе данных объектов недвижимости
                  </div>
                </li>
                <li>
                  <div className="payment_check-icon">
                    <CheckIcon fill="#c06198" width="100%" height="100%" />
                  </div>
                  <div className="payment_tariff-option-text">
                    калькуляция рассрочки различных типов
                  </div>
                </li>
                <li>
                  <div className="payment_check-icon">
                    <CheckIcon fill="#c06198" width="100%" height="100%" />
                  </div>
                  <div className="payment_tariff-option-text">
                    возможность отправить клиенту готовое УТП с рассчитанной
                    стоимостью
                  </div>
                </li>
              </ul>
              <Button pending={pending} onClick={pay} text="выбрать" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pending: selectPaymentPending(state),
  error: selectPaymentError(state),
  errorMessage: selectPaymentErrorMessage(state),
  success: selectPaymentSuccess(state),
  confirmationUrl: selectPaymentConfirmationUrl(state),
  userData: selectUserData(state),
});

const mapDispatchToProps = {
  paymentRequest: UserActionCreator.payment,
};

export const Payment = connect(mapStateToProps, mapDispatchToProps)(_Payment);
