import React, { useEffect } from "react";
import "./styles.css";
import { RootState } from "../../store";
import { UserActionCreator } from "../../store/userActions";
import { connect } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  selectUserData, selectUserToken,
  selectVerifyEmailError,
  selectVerifyEmailPending,
  selectVerifyEmailSuccess,
} from "../../store/selectors";
import { Error } from "../../components/common/Error";
import { ReactComponent as Loader } from "../../assets/loading_icon.svg";

export const _VerifyEmail = ({ pending, error, success, verifyEmail, token }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const hash = searchParams.get("hash");

  useEffect(() => {
    if (token && id && hash) {
      verifyEmail({ id, hash });
    }
  }, [token]);

  if (pending) {
    return (
      <div className="verify-email_container">
        <div className="verify-email_loader">
          <Loader fill="#818C99" stroke="#818C99" width="50%" />
        </div>
        <div className="verify-email_title">Подтверждение e-mail...</div>
      </div>
    );
  }

  if (error) {
    return (
      <Error
        title="Не удалось подтвердить e-mail"
        description="Проверьте правильность ссылки"
      />
    );
  }

  return (
    <div className="verify-email_container">
      <div className="verify-email_title">E-mail подтвержден</div>
      <div className="verify-email_description">теперь вы можете полноценно пользоваться инструментом Husam Helper</div>
      <Link to="/">продолжить</Link>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  pending: selectVerifyEmailPending(state),
  error: selectVerifyEmailError(state),
  success: selectVerifyEmailSuccess(state),
  token: selectUserToken(state),
});

const mapDispatchToProps = {
  verifyEmail: UserActionCreator.verifyEmail,
};

export const VerifyEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_VerifyEmail);
