import React from "react";
import './styles.css';

export const InfoRow = ({
    title,
    value,
    unit = '',
}) => {
    if (!title || !value) {
        return null;
    }

    return (
        <div className="info-row_container">
            <div className="info-row_title">
                {title}:
            </div>
            <div className="info-row_value">
                {`${value.toLocaleString()} ${unit}`}
            </div>
        </div>
    );
}
