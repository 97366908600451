import React, {useRef} from "react";
import { InfoRow } from "../../../../../../components/common/InfoRow";
import { getInstallmentOptionName } from "./utils";
import "./styles.css";

export const FullInfo = ({ data }) => {
    const ref = useRef(null);
    const refHeight = ref?.current?.clientHeight;
  return (
    <>
      <div className="shared-housing_container">
        <div className="shared-housing_title">Детали предложения</div>
        <InfoRow title="тип квартиры" value={data.flatType} />
        <InfoRow title="этаж" value={data.floor} />
        <InfoRow title="квадратура" value={data.area} unit="м²" />
        <InfoRow title="стоимость квадрата" value={data.areaWorth} unit="₽" />
        <InfoRow
          title="срок рассрочки"
          value={data.installmentPeriod}
          unit="мес."
        />
        <InfoRow
          title="процент первого взноса"
          value={getInstallmentOptionName(data.installmentOption)}
        />
        <InfoRow title="процент наценки" value={data.installmentPercent} />
        <InfoRow
          title="первоначальный взнос"
          value={data.firstPayment}
          unit="₽"
        />
        <InfoRow title="остаток" value={data.rest} unit="₽" />
        <InfoRow title="наценка" value={data.markup} unit="₽" />
          <div className="shared-housing_empty-space" style={{ height: `${refHeight}px`}} />
      </div>
      <div className="shared-housing_main-info" ref={ref}>
        <InfoRow title="полная стоимость" value={data.fullPrice} unit="₽" />
        <InfoRow
          title="ежемесячный платеж"
          value={data.monthlyPayment}
          unit="₽"
        />
      </div>
    </>
  );
};
