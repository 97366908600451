import React from "react";
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error-informer.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/help.svg';

export const IconRender = ({ type }) => {
    switch (type) {
        default:
            return <WarningIcon stroke="#ff8500" width="100%" height="100%" />;
        case 'error':
            return <ErrorIcon stroke="#ff0000" width="100%" height="100%" />;
        case 'help':
            return <HelpIcon stroke="#c06198" width="100%" height="100%" />;
    }
}
