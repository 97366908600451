import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../utils";
import { API_ENDPOINTS } from "../constants/common.ts";

const UserActionTypes = {
  USER_SIGN_IN: "user/signIn",
  USER_SIGN_UP: "user/signUp",
  GET_USER_INFO: "user/info/get",
  VERIFY_EMAIL: "email/verify",
  SET_NOTIFICATION: "notification/set",
  LOGOUT: "user/logout",
  SET_REMEMBER_ME: "user/rememberMe",
  RESET_PASSWORD: "user/password/reset",
    PAY: "user/pay",
};

export const UserActionCreator = {
  userSignIn: createAsyncThunk(
    UserActionTypes.USER_SIGN_IN,
    async ({ email, password }) => {
      const result = await apiClient.post(API_ENDPOINTS.userSignIn, {
        email,
        password,
      });

      return result.data;
    },
  ),
  userSignUp: createAsyncThunk(
    UserActionTypes.USER_SIGN_UP,
    async ({ email, name, password }) => {
      try {
        const result = await apiClient.post(API_ENDPOINTS.userSignUp, {
          email,
          name,
          password,
        });

        return result;
      } catch (err) {
        return err.response;
      }
    },
  ),
  getUserInfo: createAsyncThunk(UserActionTypes.GET_USER_INFO, async () => {
    const result = await apiClient.get(API_ENDPOINTS.getUserInfo);

    return result.data;
  }),
  verifyEmail: createAsyncThunk(
    UserActionTypes.VERIFY_EMAIL,
    async ({ id, hash }) => {
      const result = await apiClient.post(
        `${API_ENDPOINTS.verifyEmail}${id}/${hash}`,
      );

      return result.data;
    },
  ),
  resetPassword: createAsyncThunk(
    UserActionTypes.RESET_PASSWORD,
    async ({ email }) => {
      try {
        const result = await apiClient.post(API_ENDPOINTS.resetPassword, { email });

        return result;
      } catch (err) {
        return err.response;
      }
    },
  ),
    payment: createAsyncThunk(
        UserActionTypes.PAY,
        async ({ email, amount, returnUrl, description }) => {
            try {
                const result = await apiClient.post(API_ENDPOINTS.payment, {
                    metadataEmail: email,
                    amountValue: amount,
                    confirmationRedirectUrl: returnUrl,
                    description
                });

                return result;
            } catch (err) {
                return err.response;
            }
        },
    ),
  setNotification: createAction(UserActionTypes.SET_NOTIFICATION),
  logout: createAction(UserActionTypes.LOGOUT),
  setRememberMe: createAction(UserActionTypes.SET_REMEMBER_ME),
};
