import { createReducer } from "@reduxjs/toolkit";
import { AdminActionCreator } from "./adminActions";

const initialState = {
  uploadFiles: {
      progress: 0,
    pending: false,
    error: false,
    errorMessage: null,
    paths: [],
  }
};

export const adminReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(AdminActionCreator.uploadFiles.pending, (state, action) => {
      state.uploadFiles.pending = true;
      state.uploadFiles.error = false;
      state.uploadFiles.errorMessage = null;
      state.uploadFiles.paths = [];
    })
    .addCase(AdminActionCreator.uploadFiles.fulfilled, (state, action) => {
      state.uploadFiles.pending = false;
      state.uploadFiles.error = false;
      state.uploadFiles.paths = action.payload.path;
    })
    .addCase(AdminActionCreator.uploadFiles.rejected, (state) => {
      state.uploadFiles.pending = false;
      state.uploadFiles.error = true;
    });
});
