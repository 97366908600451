import {createAsyncThunk} from "@reduxjs/toolkit";
import {apiClient} from "../utils";
import {API_ENDPOINTS} from "../constants/common.ts";

const UserActionTypes = {
  UPLOAD_FILES: "files/upload",
};

export const AdminActionCreator = {
  uploadFiles: createAsyncThunk(
    UserActionTypes.UPLOAD_FILES,
    async ({ files, setProgress }) => {
        try {
            const response = await apiClient.post(
                API_ENDPOINTS.uploadFiles,
                {
                    files,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: progressEvent => setProgress(progressEvent),
                },
            );
            return response.data;
        } catch (err) {
            return err;
        }
    },
  ),
};
