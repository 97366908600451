import { RootState } from "./index.ts";

//// Housings ////

export const selectHousingsReducer = (state: RootState) =>
  state.housingsReducer;

export const selectHousings = (state: RootState) =>
  selectHousingsReducer(state)?.housings;
export const selectHousingsPending = (state: RootState) =>
  selectHousings(state)?.pending;
export const selectHousingsError = (state: RootState) =>
  selectHousings(state)?.error;
export const selectHousingsList = (state: RootState) =>
  selectHousings(state)?.data;

//////////////////////////

//// Shared data ////

export const selectShared = (state: RootState) => selectHousingsReducer(state)?.sharedData;

export const selectSharedPending = (state: RootState) => selectShared(state)?.pending;
export const selectSharedError = (state: RootState) => selectShared(state)?.error;
export const selectSharedData = (state: RootState) => selectShared(state)?.data;
export const selectSharedHousingData = (state: RootState) => selectSharedData(state)?.housingData;

//////////////////////////

//// Sharing data ////

export const selectSharing = (state: RootState) => selectHousingsReducer(state)?.sharingData;

export const selectSharingPending = (state: RootState) => selectSharing(state)?.pending;
export const selectSharingError = (state: RootState) => selectSharing(state)?.error;
export const selectSharingDataId = (state: RootState) => selectSharing(state)?.id;

//////////////////////////

//// InstallmentPlans ////

export const selectInstallmentPlanTypes = (state: RootState) =>
  selectHousingsReducer(state)?.installmentPlanTypes;
export const selectInstallmentPlanTypesPending = (state: RootState) =>
  selectInstallmentPlanTypes(state)?.pending;
export const selectInstallmentPlanTypesError = (state: RootState) =>
  selectInstallmentPlanTypes(state)?.error;
export const selectInstallmentPlanTypesList = (state: RootState) =>
  selectInstallmentPlanTypes(state)?.data;

export const selectPricesByFirstPayment = (state: RootState) =>
  selectHousingsReducer(state)?.housingPricesByFirstPayment;
export const selectPricesByFirstPaymentPending = (state: RootState) =>
  selectPricesByFirstPayment(state)?.pending;
export const selectPricesByFirstPaymentError = (state: RootState) =>
  selectPricesByFirstPayment(state)?.error;
export const selectPricesByFirstPaymentData = (state: RootState) =>
  selectPricesByFirstPayment(state)?.data;
export const selectRequiredPayment = (state: RootState) =>
  selectPricesByFirstPaymentData(state)?.requiredPayment;

export const selectPricesByPeriod = (state: RootState) =>
  selectHousingsReducer(state)?.housingPricesByPeriod;
export const selectPricesByPeriodPending = (state: RootState) =>
  selectPricesByPeriod(state)?.pending;
export const selectPricesByPeriodError = (state: RootState) =>
  selectPricesByPeriod(state)?.error;
export const selectPricesByPeriodData = (state: RootState) =>
  selectPricesByPeriod(state)?.data;

//////////////////////////

//// User ////

export const selectUserReducer = (state: RootState) => state.userReducer;

export const selectUserPending = (state: RootState) =>
  selectUserReducer(state).pending;
export const selectUserError = (state: RootState) =>
  selectUserReducer(state).error;
export const selectUserErrorMessage = (state: RootState) =>
    selectUserReducer(state).errorMessage;
export const selectUserData = (state: RootState) =>
  selectUserReducer(state).userData;
export const selectIsAuth = (state: RootState) => !!selectUserData(state).token;
export const selectUserToken = (state: RootState) =>
  selectUserData(state).token;

export const selectUserInfo = (state: RootState) => selectUserData(state)?.user;

export const selectIsEmailVerified = (state: RootState) => selectUserInfo(state)?.emailVerified;
export const selectIsPaid = (state: RootState) => selectUserInfo(state)?.paid;
export const selectPaidTill = (state: RootState) => selectUserInfo(state)?.paidTill;

export const selectIsTestPeriod = (state: RootState) => selectUserInfo(state)?.testPeriod;
export const selectTestPeriodTill = (state: RootState) => selectUserInfo(state)?.testPeriodTill;

export const selectIsRememberMe = (state: RootState) => selectUserReducer(state)?.rememberMe;

export const selectResetPassword = (state: RootState) => selectUserReducer(state)?.resetPassword;
export const selectResetPasswordPending = (state: RootState) => selectResetPassword(state)?.pending;
export const selectResetPasswordError = (state: RootState) => selectResetPassword(state)?.error;
export const selectResetPasswordErrorMessage = (state: RootState) => selectResetPassword(state)?.errorMessage;
export const selectResetPasswordSuccess = (state: RootState) => selectResetPassword(state)?.success;

//////////////////////////

//// Admin ////

export const selectAdmin = (state: RootState) => state.adminReducer;

export const selectUploadFiles = (state: RootState) => selectAdmin(state)?.uploadFiles;

export const selectUploadFilesPending = (state: RootState) => selectUploadFiles(state)?.pending;

export const selectUploadFilesError = (state: RootState) => selectUploadFiles(state)?.error;

export const selectUploadFilesErrorMessage = (state: RootState) => selectUploadFiles(state)?.errorMessage;

export const selectUploadFilesPaths = (state: RootState) => selectUploadFiles(state)?.paths;

//////////////////////////

//// Verify Email ////

export const selectVerifyEmail = (state: RootState) => selectUserReducer(state)?.verifyEmail;

export const selectVerifyEmailPending = (state: RootState) => selectVerifyEmail(state)?.pending;

export const selectVerifyEmailError = (state: RootState) => selectVerifyEmail(state)?.error;

export const selectVerifyEmailSuccess = (state: RootState) => selectVerifyEmail(state)?.success;

//////////////////////////

//// Notification ////

export const selectNotification = (state: RootState) => selectUserReducer(state)?.notification;

export const selectNotificationIsOpen = (state: RootState) => selectNotification(state)?.isOpen;

export const selectNotificationValue = (state: RootState) => selectNotification(state)?.value;

//////////////////////////

//// Payment ////

export const selectPayment = (state: RootState) => selectUserReducer(state)?.payment;

export const selectPaymentPending = (state: RootState) => selectPayment(state)?.pending;

export const selectPaymentError = (state: RootState) => selectPayment(state)?.error;

export const selectPaymentErrorMessage = (state: RootState) => selectPayment(state)?.errorMessage;

export const selectPaymentSuccess = (state: RootState) => selectPayment(state)?.success;

export const selectPaymentConfirmationUrl = (state: RootState) => selectPayment(state)?.confirmationUrl;

//////////////////////////
