import React from "react";
import "./styles.css";
import { COLOR_SCHEMES } from "./constants";

export const DefaultAvatar = ({ name, color, height = 56, width = 56 }) => {
  const names = name.split(" ");
  const avatarColor = color || COLOR_SCHEMES[1];
  const firstLetter = names[0] ? names[0][0] : "";
  const lastLetter = names[1] ? names[1][0] : "";
  const fontSize = height / 2;
  return (
    <div
      className="default-avatar_container"
      style={{
        background: avatarColor,
        width: `${width}px`,
        height: `${height}px`,
        fontSize: `${fontSize}px`,
      }}
    >
      {firstLetter}
      {lastLetter}
    </div>
  );
};
