export const FLAT_VIEW_TYPES = {
  commonFlat: {
    id: "commonFlat",
    name: "Обычные",
  },
  fancyViewFlat: {
    id: "fancyViewFlat",
    name: "Видовые",
  },
};

export const INSTALLMENT_PLAN_TYPES = {
  byFirstPayment: {
    id: "byFirstPayment",
    name: "по взносу",
  },
  byPeriod: {
    id: "byPeriod",
    name: "по сроку",
  },
};

export const MANUAL_AREA = 'ввести вручную';
