import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

export const PaidPeriod = ({ paid, paidTill, handleClose }) => {
    return (
        <div className="side-menu_item">
            <div className="side-menu_item-title">Подписка</div>
            {!paid && (
                <>
                    <div className="paid-period_container">
                        У вас нет активной подписки
                    </div>
                    <div>
                        <Link className="paid-period_link" to="/payment" onClick={handleClose}>
                            оформить
                        </Link>
                    </div>
                </>
            )}
            {paid && paidTill && (
                <>
                    <div className="paid-period_container">
                        Истекает {paidTill}
                    </div>
                    <div>
                        <Link className="paid-period_link" to="/payment" onClick={handleClose}>
                            продлить
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
};
