import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";

export const TestPeriod = ({ testPeriod, testPeriodTill, handleClose }) => {
    return (
        <div className="side-menu_item">
            <div className="side-menu_item-title">Тестовый период</div>
            {testPeriod && testPeriodTill && (
                <>
                    <div className="test-period_container">
                        Истекает {testPeriodTill}
                    </div>
                    <div>
                        <Link className="test-period_link" to="/payment" onClick={handleClose}>
                            оформить подписку
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
};
