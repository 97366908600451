import React, { useEffect, useState } from "react";
import "./styles.css";
import { SelectInstallmentOption } from "../components/SelectInstallmentOption";
import { getAreaWorth, getInstallmentPeriods, getMarkup } from "./utils.ts";
import { Input } from "../../Input";
import { RootState } from "../../../../store/index.ts";
import { HousingsActionCreator } from "../../../../store/housingsActions.ts";
import { connect } from "react-redux";
import {
  selectPricesByPeriodData,
  selectPricesByPeriodError,
  selectPricesByPeriodPending,
} from "../../../../store/selectors.ts";
import { getAreas, getFlatViewTypes, getFloorRanges } from "../utils.ts";
import { ByPeriodSkeleton } from "./components/ByPeriodSkeleton";
import { Error } from "../../Error";
import { InfoRow } from "../../InfoRow";
import { MANUAL_AREA } from "../constants";

export const _ByPeriod = ({
  pending,
  error,
  housingName,
  pricesByPeriod,
  fetchPricesByPeriod,
  setShareData,
}) => {
  const [installmentPeriod, setInstallmentPeriod] = useState(0);
  const [floorRange, setFloorRange] = useState();
  const [flatViewType, setFlatViewType] = useState();
  const [areaWorth, setAreaWorth] = useState(0);
  const [firstPayment, setFirstPayment] = useState(0);
  const [fullPrice, setFullPrice] = useState(0);
  const [rest, setRest] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [markup, setMarkup] = useState(0);
  const [area, setArea] = useState(0);

  const floorRanges = getFloorRanges(pricesByPeriod);
  const flatViewTypes = getFlatViewTypes(floorRange);
  const areas = getAreas(pricesByPeriod);
  const installmentPeriods = getInstallmentPeriods(floorRange);
  const isFullPayment = installmentPeriod?.value?.percent !== "full";

  const shareData = {
    floor: floorRange?.value?.floorRange,
    flatType: flatViewType?.value?.name,
    area: area?.value,
    installmentPeriod: installmentPeriod?.value?.period,
    installmentPercent: installmentPeriod?.value?.percent,
    areaWorth,
    firstPayment: parseInt(firstPayment),
    fullPrice,
    rest,
    monthlyPayment,
    markup,
  };

  useEffect(() => {
    if (installmentPeriod) {
      setAreaWorth(getAreaWorth(floorRange, flatViewType, installmentPeriod));
    }
    setMonthlyPayment(0);
  }, [installmentPeriod, flatViewType]);

  useEffect(() => {
    fetchPricesByPeriod(housingName);
  }, []);

  useEffect(() => {
    if (area && area.value > 0 && areaWorth > 0) {
      setFullPrice(area.value * areaWorth);
    }
    if (firstPayment > 0 && fullPrice > 0) {
      setRest(fullPrice + markup - firstPayment);
    }
    if (fullPrice > 0) {
      setMarkup(getMarkup(fullPrice, installmentPeriod));
    }
    if (fullPrice > 0 && installmentPeriod.value.period > 1) {
      const monthlyPayment =
        (fullPrice + markup - firstPayment) / installmentPeriod.value.period;
      setMonthlyPayment(Math.round(monthlyPayment));
    }
  });

  useEffect(() => {
    setShareData(shareData);
  }, [shareData]);

  useEffect(() => {
    setFlatViewType(null);
    setFullPrice(0);
    setArea(null);
    setFullPrice(0);
    setMarkup(0);
    setFirstPayment(0);
    if (installmentPeriods) {
      setInstallmentPeriod(installmentPeriods?.full);
    }
  }, [floorRange]);

  if (pending) {
    return <ByPeriodSkeleton />;
  }

  if (error) {
    return (
      <Error
        title="Данные о рассрочке не загрузились"
        retryCallback={() => fetchPricesByPeriod(housingName)}
      />
    );
  }

  return (
    <>
      <SelectInstallmentOption
        options={floorRanges}
        label="выберите этажи"
        selectedOption={floorRange}
        setSelectedOption={setFloorRange}
      />
      {floorRange && (
        <>
          <SelectInstallmentOption
            options={flatViewTypes}
            label="выберите тип квартиры"
            selectedOption={flatViewType}
            setSelectedOption={setFlatViewType}
          />
          <SelectInstallmentOption
            options={areas}
            label="выберите квадратуру"
            selectedOption={area}
            setSelectedOption={setArea}
          />
          {area && area.name === MANUAL_AREA && (
            <Input
              value={area.value}
              onChange={(value) => setArea({ name: MANUAL_AREA, value })}
              label="введите квадратуру"
            />
          )}
          <SelectInstallmentOption
            options={installmentPeriods}
            label="выберите срок рассрочки"
            selectedOption={installmentPeriod}
            setSelectedOption={setInstallmentPeriod}
          />
        </>
      )}
      <div className="first-payment_container">
        <Input
          name="areaWorth"
          type="number"
          placeholder="0 ₽"
          value={areaWorth > 0 && areaWorth}
          onChange={setAreaWorth}
          label="стоимость квадрата:"
        />
        {isFullPayment && (
          <Input
            name="firstPayment"
            type="number"
            placeholder="0 ₽"
            value={firstPayment > 0 && firstPayment}
            onChange={setFirstPayment}
            label="первоначальный взнос:"
          />
        )}
        <InfoRow
          title="полная стоимость"
          value={(fullPrice + markup).toLocaleString()}
          unit="₽"
        />
        {firstPayment > 0 && (
          <InfoRow title="остаток" value={rest.toLocaleString()} unit="₽" />
        )}
        {isFullPayment && (
          <>
            <InfoRow
              title="ежемесячный платеж"
              value={monthlyPayment.toLocaleString()}
              unit="₽"
            />
            <InfoRow title="наценка" value={markup.toLocaleString()} unit="₽" />
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pricesByPeriod: selectPricesByPeriodData(state),
  pending: selectPricesByPeriodPending(state),
  error: selectPricesByPeriodError(state),
});

const mapDispatchToProps = {
  fetchPricesByPeriod: HousingsActionCreator.fetchPricesByPeriod,
};

export const ByPeriod = connect(mapStateToProps, mapDispatchToProps)(_ByPeriod);
