import axios from "axios";
import {realtyStore} from "../store";
import { IMAGE_SIDES } from "../constants/common";
import {selectUserData} from "../store/selectors";



const bearerToken = document.cookie
  .split("; ")
  .find((row) => row.startsWith("userToken="))
  ?.split("=")[1];

let store

export const injectStore = (storeVal) => {
  store = storeVal;
}

export const apiClient = axios.create({
  responseType: "json",
});

apiClient.interceptors.request.use(config => {
  config.headers.authorization = `Bearer ${store.getState().userReducer.userData.token || decodeURIComponent(bearerToken)}`
  return config
});

export const getImageBiggerSide = (imageUrl: string) => {
  const img = new Image();
  img.src = imageUrl;
  if (img.height > img.width) {
    return IMAGE_SIDES.height;
  }

  return IMAGE_SIDES.width;
};

export const isShared = window?.location?.pathname.includes("shared");

export const getRandomNumber = (max: number) => Math.floor(Math.random() * (max + 1));
