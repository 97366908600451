import React from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { IconRender } from "./IconRender";

export const InfoBar = ({ type, text, link, button, className }) => {
  return (
    <>
      <div className={"info-bar_container " + (className ? className : '')}>
        <div className="info-bar_warning-icon">
          <IconRender type={type} />
        </div>
        <div className="info-bar_text">
          {text}
          {link && <Link to={link.href}>{link.text}</Link>}
        </div>
      </div>
    </>
  );
};
