import React from "react";
import { ReactComponent as DefaultImage } from "../../../assets/building.svg";
import "./styles.css";

export const HousingItem = ({ housing, onClick, key }) => {
  return (
    <li className="housing-item_container" onClick={onClick} key={key}>
      <div
        className="housing-item_image-container"
      >
        {housing.images[0] ? (
          <div className="housing-item_image"
               style={{
                   backgroundImage: `url(${housing.images[0]}) `,
                   backgroundRepeat: "no-repeat",
                   backgroundSize: "cover",
                   backgroundPosition: "center",
               }}
          >
              <div className="housing-item_blur">
                  <img src={housing.images[0]} width="100%"/>
              </div>
          </div>
        ) : (
            <div className="housing-item_default-image">
            <DefaultImage width="100%" height="100%" />
          </div>
        )}
      </div>
      <div className="housing-item_info">
        <div className="housing-item_title">{housing.housingName}</div>
        <div className="housing-item_builder">{housing.builder}</div>
        <div className="housing-item_address">{housing.address}</div>
      </div>
    </li>
  );
};
