import { RootState } from "../../../store";
import { connect } from "react-redux";
import {selectIsPaid, selectIsTestPeriod, selectTestPeriodTill} from "../../../store/selectors";
import { Error } from "../../common/Error";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { useNavigate } from "react-router-dom";

export const _PaidHoc = ({ children, paid, testPeriod, testPeriodTill }) => {
  const navigate = useNavigate();

  const goToPayment = () => {
    navigate("/payment/");
  };

  if (paid || testPeriod) {
    return children;
  }

  return (
    <Error
      title="Нет доступа к разделу"
      description="Для получения доступа необходимо оформить подписку"
      buttonText={"выбрать тариф"}
      retryCallback={goToPayment}
      icon={<LockIcon />}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  paid: selectIsPaid(state),
  testPeriod: selectIsTestPeriod(state),
  testPeriodTill: selectTestPeriodTill(state),
});

const mapDispatchToProps = {};

export const PaidHoc = connect(mapStateToProps, mapDispatchToProps)(_PaidHoc);
