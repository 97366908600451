export const COLOR_SCHEMES = [
    '#e072ff',
    '#9516b9',
    '#c80303',
    '#ff8181',
    '#8195ff',
    '#0120c1',
    '#0191c1',
    '#74d6f7',
    '#00c199',
    '#a7e3d6',
    '#007624',
    '#a0ebb7',
    '#ffaa5e',
    '#ec7001',
    '#910000'
];
