import React from "react";
import { HousingSkeletonItem } from "./HousingSkeletonItem";
import "./styles.css";
import { Skeleton } from "../../../components/common/Skeleton";

export const HousingsSkeleton = () => {
  return (
    <div className="housings_container">
      <Skeleton className="housings-skeleton_search-bar" />
      <div className="housings_list">
        {Array.from({ length: 6 }, () => (
          <HousingSkeletonItem />
        ))}
      </div>
    </div>
  );
};
